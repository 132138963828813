import React from 'react';
import { graphql } from 'gatsby';
import PageBody from 'components/pages/page/PageBody';
import { IPageData } from 'components/pages/page/types';

const Page: React.FC<IPageData> = (props) => <PageBody {...props} />;

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      _rawContent
      buttonLink
      buttonText
      seoTitle
      seoDescription
      h1Text
      descriptionText
      adCode
      showBriefGenerator
      subtitleText
      subtitleUrl
      presetsButtonText
      presetsButtonUrl
    }
  }
`;
